import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { menu_items, menu_items_two } from "../constants/data";
import { Logo, Logolight } from "../constants/import";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showPower, setShowPower] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleShowPower = () => {
    setShowPower(!showPower);
  };

  const [logo, setlogo] = useState(Logo);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY; // Vertical scroll position
    setScrollPosition(position);

    console.log(position);
    var navroot = document.getElementById("navbarroot");
    var menulist = document.getElementsByClassName("menulist");

    if (position > 300) {
      navroot.style.backgroundColor = "#ffffff";
      for (let i = 0; i < menulist.length; i++) {
        menulist[i].style.color = "#023428"; // Set color to white
      }

      //  setlogo(Logo)
    } else {
      navroot.style.backgroundColor = "#ffffff57";
      for (let i = 0; i < menulist.length; i++) {
        menulist[i].style.color = "#ffffff"; // Set color to white
      }
      // setlogo(Logolight)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleClick = () => setNav(!nav);
  return (
    <div
      id="navbarroot"
      className="w-screen h-[80px] z-10 bg-[#ffffff6b] fixed top-0  drop-shadow-lg px-6 md:px-20"
    >
      <div className=" flex justify-between items-center w-full h-full">
        <Link to={"/"}>
          <img
            src={logo}
            style={{
              height: "40px",
            }}
          />
        </Link>
        <div className="hidden md:flex pr-4">
          <ul
            id="menulist1"
            className="hidden  md:flex text-sm text-white font-semibold"
          >
            {menu_items.map((menu, index) => (
              <li key={index}>
                <Link
                  to={menu.link}
                  smooth={true}
                  duration={500}
                  className="hover:bg-white menulist rounded px-2 py-1 "
                >
                  {menu.name}
                </Link>
              </li>
            ))}
            <div className="relative  ">
              <li
                className=" cursor-pointer flex items-center menulist hover:bg-white rounded    "
                onClick={toggleDropdown}
              >
                {"Projects"} <AiFillCaretDown size={22} className="ml-1" />
              </li>
              {isDropdownOpen && (
                <div className="px-2 py-1 origin-top-right top-10 -right-40 absolute w-48 z-[10000000] text-sm bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="relative ">
                      <li
                        className="text-black cursor-pointer flex items-center justify-between "
                        onClick={toggleShowPower}
                      >
                        <p className="text-sm">{"Project Juriya"}</p>
                        <IoIosArrowDropdown size={22} className="ml-1" />
                      </li>
                      {/* ShowPowerBi Menu Items */}
                      {showPower && <PowerBiMenuItems />}
                    </div>
                    <div className="relative">
                      <li className="text-black cursor-pointer flex items-center">
                        <Link
                          to={"/project-acresal"}
                          smooth={true}
                          duration={500}
                          className="text-black  block py-1 text-sm"
                        >
                          {"ACReSAL Adadamwa"}
                        </Link>
                      </li>

                      <li className="text-black cursor-pointer flex items-center">
                        <Link
                          to={"/greentech"}
                          smooth={true}
                          duration={500}
                          className="text-black  block py-1 text-sm"
                        >
                          {"Greentech Solution"}
                        </Link>
                      </li>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {menu_items_two.map((menu, index) => (
              <li key={index}>
                <Link
                  to={menu.link}
                  smooth={true}
                  duration={500}
                  className="hover:bg-white menulist rounded px-2 py-1 "
                >
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      <ul
        className={!nav ? "hidden" : "absolute bg-white w-full px-6 "}
        style={{ zIndex: 10000 }}
      >
        {menu_items.map((menu, index) => (
          <li key={index} className="border-b   border-zinc-300 w-full">
            <Link to={menu.link} smooth={true} duration={500}>
              {menu.name}
            </Link>
          </li>
        ))}
        <div className="relative">
          <li
            className="text-black cursor-pointer flex items-center border-b border-zinc-300"
            onClick={toggleDropdown}
          >
            {"Projects"} <AiFillCaretDown size={20} className="ml-1" />
          </li>
          {isDropdownOpen && (
            <div className="px-2 py-1 origin-top-right top-10 left-20 absolute text-sm w-48 z-[10000000]  bg-white ring-1 ring-black ring-opacity-5">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div className="relative ">
                  <li
                    className="text-black cursor-pointer flex items-center justify-between "
                    onClick={toggleShowPower}
                  >
                    <p className="text-sm">{"Project Juriya"}</p>
                    <IoIosArrowDropdown size={22} className="ml-1" />
                  </li>
                  {/* ShowPowerBi Menu Items */}
                  {showPower && <PowerBiMenuItems />}
                </div>
                <div className="relative">
                  <li className="text-black cursor-pointer flex items-center">
                    <Link
                      to={"/project-acresal"}
                      smooth={true}
                      duration={500}
                      className="text-black  block py-1 text-sm"
                    >
                      {"ACReSAL Adadamwa"}
                    </Link>
                  </li>
                </div>
              </div>
            </div>
          )}
        </div>
        {menu_items_two.map((menu, index) => (
          <li key={index} className="border-b   border-zinc-300 w-full  ">
            <Link to={menu.link} smooth={true} duration={500}>
              {menu.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;

const PowerBiMenuItems = () => {
  return (
    <>
      <div className="px-2 py-1 origin-top-right top-5 -right-[140px] absolute w-40 z-[10000000]  bg-white ring-1 ring-black ring-opacity-5">
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <Link
            to={"/project-juriya-dashboard-year-one"}
            smooth={true}
            duration={500}
            className="text-black  block py-1 text-sm border-b border-b-zinc-400"
          >
            {"Year 1"}
          </Link>
          <Link
            to={"/project-juriya-dashboard-year-two"}
            smooth={true}
            duration={500}
            className="text-black  block py-1 text-sm border-b border-b-zinc-400"
          >
            {"Year 2"}
          </Link>
          <Link
            to={"/project-juriya-dashboard-year-three"}
            smooth={true}
            duration={500}
            className="text-black  block py-1 text-sm border-b border-b-zinc-400"
          >
            {"Year 3"}
          </Link>
          <Link
            to={"/project-juriya-dashboard-year-one-fa-assesment"}
            smooth={true}
            duration={500}
            className="text-black  block py-1 text-sm border-b border-b-zinc-400"
          >
            {"Y1 FA Assessment"}
          </Link>
          <hr />

          <Link
            to={"/project-juriya-dashboard-year-two-fa-assesment"}
            smooth={true}
            duration={500}
            className="text-black  block py-1 text-sm border-b border-b-zinc-400"
          >
            {"Y2 FA Assessment"}
          </Link>
        </div>
      </div>
    </>
  );
};
